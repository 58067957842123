import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { GLOBALS } from '../shared/globals';
import { isEmpty, cloneDeep, isArray } from 'lodash-es';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormTemplate, WorkItemTypes, WorkItemTypeValues } from '../shared/report.enum';
import { SectionTemplates } from '../shared/models/section.model';
import { ArrangementOfWorkItem } from '../shared/models/report.model';
import { TypeaheadValue } from 'proceduralsystem-clientcomponents';
import { ReportUtils } from '../shared/utils/report.utils';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  /**
   * Function to check is it day before today
   * @param date Date
   */
  public isDayBeforeToday(date: string): boolean {
    const passedDate = moment(this.convertDate(date)).utc().format(GLOBALS.dateFormat);
    const today = moment().utc().format(GLOBALS.dateFormat);

    return (moment(this.convertDate(date)).utc().isBefore(moment().utc().format()) && passedDate !== today);
  }

  /**
   * @description Method to get current date in DD/MM/YYYY format
   *
   * @returns {string} Date value
   */
  public getCurrentDate(): string {
    return moment.utc().format(GLOBALS.dateFormat);
  }

  /**
   * Convert date string to BE Acceptable date value
   * @param date
   */
  public convertDateStringToBEAcceptableValue(date: string): string {
    if (!date) {
      return null;
    }

    return moment.utc(date, [GLOBALS.dateTimeFormat]).format();
  }

  /**
   * Function to enable needed fields in form
   * @param form Form Group
   * @param templateId Sectipon template id
   */
  public showOrHideFormFieldsBasedOnTemplate(form: UntypedFormGroup, templateId: number): void {
    if (!isEmpty(SectionTemplates[FormTemplate[templateId]])) {
      SectionTemplates[FormTemplate[templateId]].forEach((controlName) => {
        if (form.controls[controlName]) {
          form.controls[controlName].enable();
        }
      });
    }
  }

  /**
   * Function to get multiselect value
   * @param multiselectData Data
   * @param form From group
   * @param formField Form field
   * @param key Data key
   */
  public getMultiselectValues(multiselectData: any, form: UntypedFormGroup, formField: string, key: string) {
    if (!isEmpty(multiselectData) && !isEmpty(form.controls[formField].value)) {
      return form.controls[formField].value.map(
        item => multiselectData.find(selectItem => selectItem[key] === item));
    } else {
      return null;
    }
  }

  /**
   * Function to clear form fields
   * @param form
   * @param field
   */
  public clearFormFields(form: UntypedFormGroup, field: Array<string>) {
    field.forEach((item) => {
      form.controls[item].setValue(null);
    });
  }

  /**
   * Function to generate random Id
   */
  public generateId(): string {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0].toString().substring(0,3);
  }

  /**
   * Function to clear object null values
   * @param object Object with data
   */
  public cleanObjectNullValue(object: any) {
    Object.keys(object).forEach((key) => {
      if ((object[key] === null && key !== 'businessDaySectionDuration') || (object[key] === undefined && key !== 'businessDaySectionDuration')) {
        delete object[key];
      }
    });
    return object;
  }

  /**
   * Function to convert number to boolean
   * @param data Passed data
   */
  public convertNumberToBoolean(data: any): boolean {
    switch (data) {
      case 0:
        return false;
      case 1:
        return true;
      default:
        return data;
    }
  }

  /**
   * Function to check is start time is before end time
   * @param startTime Start Time
   * @param endTime End Time
   */
  public isStartTimeBeforeEndTime(startTime: string, endTime: string): boolean {
    return moment(startTime, GLOBALS.timeFormat).isBefore(moment(endTime, GLOBALS.timeFormat));
  }

  /**
   * Function to convert boolean to number
   * @param param Passed param
   */
  public convertBoolenToNumber(param: any): number {
    if (param === true) {
      return 1;
    } else if (param === false) {
      return 0;
    } else {
      return param;
    }
  }

  /**
   * Convert date string to time value
   * @param date
   */
  public convertDateStringToTime(date: string): any {
    if (!date) {
      return null;
    }

    return this.isNotFormattedDate(date) ?
      moment.utc(date, GLOBALS.dateTimeFormat).format(GLOBALS.timeFormat) :
      moment.utc(date).format(GLOBALS.timeFormat);
  }

  /**
   * Method to set time to given date
   * @param date
   * @param time
   */
  public setTimeToDate(date: string, time: string): any {
    const h = time.split(':')[0];
    const m = time.split(':')[1];
    return moment.utc(this.convertDate(date)).set({ h: +h, m: +m }).format();
  }

  /**
   * @description Show field only when other field's value is equal to passed value
   *
   * @param {Subscription} [subscription]
   * @param {UntypedFormGroup} [form]
   * @param {string} [fieldToCheck]
   * @param {string} [valueToCheck]
   * @param {string} [fieldToHide]
   */
  public showFieldIfOtherFieldValueIs(
    subscription: Subscription,
    form: UntypedFormGroup,
    fieldToCheck: string,
    valueToCheck: any,
    fieldToHide: string
  ): void {
    form.controls[fieldToHide].disable();
    subscription.add(form.controls[fieldToCheck].valueChanges.subscribe(value => {
      if ((isArray(value) && !!value.find(val => (val === valueToCheck || parseInt(val, 10) === valueToCheck))) ||
        value === valueToCheck ||
        parseInt(value, 10) === valueToCheck) {
        form.controls[fieldToHide].enable();
      } else {
        form.controls[fieldToHide].disable();
      }
    }));
  }

  /**
   * Methot to add validator(s) to specific field if other specific field has any value
   * @param subscription
   * @param form
   * @param fieldToCheck
   * @param validatorsToSet
   * @param fieldToAddValidatorTo
   */
  public addValidatorIfOtherFieldHasValue(
    subscription: Subscription,
    form: UntypedFormGroup,
    fieldToCheck: string,
    validatorsToSet: any[],
    fieldToAddValidatorTo: string
  ): void {
    subscription.add(form.controls[fieldToCheck].valueChanges.subscribe(value => {
      if ((value && !isArray(value)) || (value && isArray(value) && !isEmpty(value))) {
        form.controls[fieldToAddValidatorTo].setValidators(validatorsToSet);
        form.controls[fieldToAddValidatorTo].updateValueAndValidity({ emitEvent: false });
      } else {
        form.controls[fieldToAddValidatorTo].clearValidators();
        form.controls[fieldToAddValidatorTo].updateValueAndValidity({ emitEvent: false });
      }
    }));
  }

  public filterTypeAheadArrayForNoPillsInputTypeAheadBasedOnTitle(formArray: UntypedFormArray, controlName: string, valuesToFilter: any[]): any[] {
    const res: any[] = [];
    formArray.controls.forEach((form: UntypedFormGroup) => {
      if (!isEmpty(form.controls[controlName].value)) {
        res.push(form.controls[controlName].value);
      }
    });

    return cloneDeep(valuesToFilter).filter(val => res.indexOf(!isEmpty(val) ? val.title : -1) === -1);
  }

  /**
   * Validate dates based on user input
   * @param enteredDates
   */
  public validateDates(dates: any, currentWeek: number, dateRange: boolean): any {
    const isSameYear = moment.utc().year() === moment.utc(dates[0], GLOBALS.dateFormat).year();
    const weekStart = moment.utc().subtract(1, 'days').toDate();
    const dateToCheck = moment(dates[0], 'DD/MM/YYYY');
    const yearOnThursday = dateToCheck.isoWeekday(4).year();
    const weekEnd = moment.utc().year(yearOnThursday).isoWeek(currentWeek).isoWeekday(7).toDate();
    
    const errors = [moment.utc(dates[0].trim(), GLOBALS.dateFormat).isoWeek() !== currentWeek,
    !moment.utc(dates[0].trim(), GLOBALS.dateFormat).isValid(),
    isSameYear ? moment.utc(dates[0].trim(), GLOBALS.dateFormat).toDate() >= weekEnd : false,
    moment.utc(dates[0].trim(), GLOBALS.dateFormat).toDate() < weekStart,
    (dateRange ? moment.utc(dates[0].trim(), GLOBALS.dateFormat).toDate() >= moment.utc(dates[1].trim(), GLOBALS.dateFormat).toDate() : false),
    (dateRange ? moment.utc(dates[1].trim(), GLOBALS.dateFormat).isoWeek() !== currentWeek : false),
    (dateRange ? !moment.utc(dates[1].trim(), GLOBALS.dateFormat).isValid() : false),
    (dateRange ? moment.utc(dates[1].trim(), GLOBALS.dateFormat).toDate() <= moment.utc(dates[0].trim(), GLOBALS.dateFormat).toDate() : false),
    (dateRange && isSameYear ? moment.utc(dates[1].trim(), GLOBALS.dateFormat).toDate() > weekEnd : false),
    (dateRange ? moment.utc(dates[1].trim(), GLOBALS.dateFormat).toDate() < weekStart : false)];

    return errors.indexOf(true) !== -1;
  }

  /**
   * Convert date string to BE Acceptable date value
   * @param date
   */
  public convertDate(date: string): string {
    if (!date) {
      return null;
    }

    return this.isNotFormattedDate(date) ?
      moment.utc(date, GLOBALS.dateTimeFormat).format() :
      date;
  }

  /**
   * Check if date isn't already converted and convert to time value
   * @param date
   */
  public convertTime(date: string): string {
    if (!date) {
      return null;
    }

    return !GLOBALS.timeFormatRegex.test(date) ?
      this.getTimeFromDate(date) :
      date;
  }

  /**
   * Method to check if day (by date) is default sitting day (2day, 3day, 4day)
   * @param date
   */
  public isDayDefaultSittinDayByDate(date: string): boolean {
    const day = this.getDayNameFromDate(date);

    return day ? ((day.search(/Tuesday/i) === 0) ||
      (day.search(/Wednesday/i) === 0) ||
      (day.search(/Thursday/i) === 0)) : null;
  }

  /**
   * Method to time value from date
   * @param date
   */
  public getTimeFromDate(date: string): string {
    return date ? moment.utc(this.convertDate(date)).format(GLOBALS.timeFormat) : null;
  }

  /**
   * Metho to get the name of date day
   * @param date
   */
  public getDayNameFromDate(date: string): string {
    return date ? moment.utc(this.convertDate(date)).format(GLOBALS.dayNameFormat) : null;
  }

  /**
   * Method to calculate time difference
   * @param start Start time
   * @param end End time
   */
  public timeDifference(start, end): any {
    return moment.utc(moment(end, 'HH:mm').diff(moment(start, 'HH:mm'))).format('H:m');
  }

  /**
   * Compare 2 arrays and return difference
   * @param a1
   * @param a2
   */
  public compareDaysArrays(a1, a2) {
    let a = {}, diff = [];

    for (const day of a1) {
      a[day] = true;
    }

    for (const day of a2) {
      if (a[day]) {
        delete a[day];
      } else {
        a[day] = true;
      }
    }

    for (let k in a) {
      diff.push(k);
    }

    return diff;
  }

  /**
   * Method to get arrangement data from work item form (if exists)
   * @param data
   */
  public getArrangementDataFromWorkItemForm(data: any): ArrangementOfWorkItem[] {
    const arrangements: ArrangementOfWorkItem[] = [];
    if (!isEmpty(data.arrangmentsGroup)) {
      data.arrangmentsGroup.forEach(element => {
        if (!isEmpty(element.arrangement)) {
          const arrangementData: ArrangementOfWorkItem = {
            arrangementOfWorkItemId: element.arrangementId || 0,
            arrangementOfWorkItemDescription: element.arrangement,
            isDissentingMembersAssigned: element.dissenting,
            isArrangementAppearOnFront: element.isArrangementAppearOnFront,
            dissentingMembers: (element.dissenting && !isEmpty(element.arrangementMembers)) ?
              element.arrangementMembers.map(member => ({ id: member.value, name: member.title })) :
              null
          };
          arrangements.push(arrangementData);
        }
      });
    }

    return arrangements;
  }

  /**
   * Method to get workItemTypeInfo value from workItem form workItemType control value
   * @param workItemType
   */
  public getWorkItemTypeInfoValueFromWorkItemForm(workItemType: string): number {
    switch (workItemType) {
      case WorkItemTypes.bills:
        return WorkItemTypeValues.bills;
      case WorkItemTypes.motions:
        return WorkItemTypeValues.motions;
      case WorkItemTypes.statements:
        return WorkItemTypeValues.statements;

      default:
        return null;
    }
  }

  /**
   * Method to check if date is not already formatted
   * @param date
   */
  private isNotFormattedDate(date: string): boolean {
    return !/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/.test(date) &&
      !/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date)
  }

  /**
   * Create `;` delimited recipient string for BE
   */
  public convertRecipientsToString(value: TypeaheadValue<number>[]) {
    return value.length > 1 ? value.map(x => x.title).join('; ') : value[0].title;
  }

  /**
   * addDevEmails
   */
  public addDevEmails(recipientsEmails: TypeaheadValue<number>[]): any {
    const devEmails = [
      'akmal.kadirov@oireachtas.ie',
      'selim.kayali@oireachtas.ie',
      'dmitrijs.ivascenko@oireachtas.ie',
      'artems.boroviks@oireachtas.ie',
      'janis.kope@oireachtas.ie',
      'arturs.korotkovs@oireachtas.ie',
      'fidan.heydarli@oireachtas.ie',
      'anastasija.gutmane@oireachtas.ie',
      'viesturs.berzins@oireachtas.ie',
      'lilit.grigoryan@oireachtas.ie',
      'ineta.lapina@oireachtas.ie',
      'viktorija.resetnikova@oireachtas.ie',
      'Contact_Group',
      'TEST'
    ];
    // Only temporary for Development.
    let i = 100000;
    devEmails.forEach(element => {
      recipientsEmails.push({ title: element, value: i });
      i++;
    });
    return recipientsEmails;
  }

  public validateArrangementLength(form: UntypedFormControl, value: string) {
    form.setErrors(
      ReportUtils.getRteInputTrueLength(value) > GLOBALS.rteArrangementMaxLength
        ? {'maxlength': true}
        : null
    );
  }
}
